import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import ArrowIconSVG from "../../static/images/icons/arrow.png";

interface StyledContainerProps {
  height?: number | string;
  backgroundColor?: string;
  noBorder?: boolean;
  className?: string;
  title?: string;
  flex?: boolean;
  titleVarian?: "big" | "small";
  showControls?: boolean;
  controlsOnHover?: boolean;
  controlsColor?: "yellow" | "grey";
  customTop?: number;
  wrap?: boolean;
  overflow?: boolean;
  biggerZIndex?: boolean;
  fullWidth?: boolean;
  anim?: boolean;
  biggerContainer?: boolean;
  customInnerClassNames?: string;
}

interface ContainerProps {
  height?: number | string;
  backgroundColor?: string;
  noBorder?: boolean;
  overflow?: boolean;
  biggerZIndex?: boolean;
  fullWidth?: boolean;
  anim?: boolean;
  biggerContainer?: boolean;
}

const Container = styled.div<ContainerProps>`
  ${({ height }) => height && `height: ${height}px`};
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`};
  outline: ${({ noBorder }) => (noBorder ? "none" : "1px solid #E9E3E3")};

  ${({ overflow }) => overflow && "overflow: hidden"};
  position: relative;
  ${({ fullWidth }) =>
    !fullWidth &&
    `
    max-width: 1300px;
  `};

  ${({ biggerContainer }) => biggerContainer && "max-width: 70%"};
  margin: 0 auto;
  width: 100%;
  ${({ biggerZIndex }) => biggerZIndex && `z-index: 2`};

  ${({ fullWidth }) =>
    !fullWidth &&
    `
    @media screen and (max-width: 1280px) {
  max-width: 1100px;
    }
`};
`;

interface FlexInnerProps {
  wrap?: boolean;
  overflow?: boolean;
}

const FlexInner = styled.div<FlexInnerProps>`
  display: flex;
  justify-content: space-between;
  position: relative;

  ${({ wrap }) =>
    wrap &&
    `
    flex-wrap: wrap;

    `};

  ${({ overflow }) =>
    overflow &&
    `
    width: 100%;
    margin: 0 auto;
    transition: all 0.5s ease;
    overflow-x: scroll;
    scrollbar-width: none; 
    &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    `};
`;

interface TitleProps {
  titleVarian?: "small" | "big";
}

const Title = styled.h1<TitleProps>`
  font-weight: normal;
  font-size: ${({ titleVarian }) =>
    titleVarian === "big" ? "2rem" : titleVarian === "small" && "1.4rem"};
  margin-bottom: ${({ titleVarian }) =>
    titleVarian === "big" ? "1.5rem" : titleVarian === "small" && "0.5rem"};
`;

interface ArrowIconProps {
  direction: "right" | "left";
  flex?: boolean;
  color?: "yellow" | "grey";
  customTop?: number;
}

const ArrowIcon = styled.div<ArrowIconProps>`
  pointer-events: ${({ flex }) => !flex && "none"};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: ${({ customTop }) => (customTop ? `${customTop}%` : "50%")};
  transform: translateY(-50%);
  z-index: 10;
  ${({ direction }) =>
    direction === "right"
      ? "right: 1.5rem"
      : direction === "left" && "left: 1.5rem"};
`;

const ArrowIconRight = styled.img`
  transform: rotate();
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

const ArrowIconLeft = styled.img`
  transform: rotate(180deg);
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

export const StyledContainer: React.FC<StyledContainerProps> = ({
  children,
  height,
  backgroundColor,
  noBorder,
  className,
  title,
  flex,
  titleVarian,
  showControls,
  controlsOnHover,
  controlsColor,
  customTop,
  overflow,
  wrap,
  biggerZIndex,
  fullWidth,
  anim,
  biggerContainer,
  customInnerClassNames,
}) => {
  // Scroll set up
  const incrementor = 500;
  const [containerPosition, setContainerPosition] = useState(0);
  const [containerMaxWidth, setContainerMaxWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<NodeJS.Timeout | null>(null);
  const [hover, setHover] = useState(false);

  // Get the max scroll value of container
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setTimeout(() => {
        setContainerMaxWidth(container.scrollWidth - container.clientWidth);
      }, 500);
    }
  }, []);

  // When containerPosition changes change scroll left position
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: 0,
        left: containerPosition,
        behavior: "smooth",
      });
    }
  }, [containerPosition]);

  // Setter when user scrolls
  const onScollHandler = () => {
    if (animation) {
      clearTimeout(animation);
    }
    const container = containerRef.current;
    if (container) {
      setAnimation(
        setTimeout(() => setContainerPosition(container.scrollLeft), 200)
      );
    }
  };

  // Setter when user click on arrows
  const setContainerRightPosition = useCallback(() => {
    if (containerPosition + incrementor > containerMaxWidth) {
      setContainerPosition(
        (prevState) => prevState + containerMaxWidth - containerPosition + 10
      );
    } else {
      setContainerPosition((prevState) => prevState + incrementor);
    }
  }, [containerPosition, containerMaxWidth, incrementor]);

  const setContainerPositionLeft = useCallback(() => {
    if (containerPosition > 0) {
      setContainerPosition((prevState) => prevState - incrementor);
    }
  }, [containerPosition, incrementor]);

  /* Detect whether touch screen */
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    const resizeFunction = () => {
      function isTouchDevice() {
        return (
          "ontouchstart" in window ||
          navigator.maxTouchPoints > 0 ||
          navigator.msMaxTouchPoints > 0
        );
      }
      setIsTouch(isTouchDevice());
    };
    resizeFunction();
    window.addEventListener("resize", resizeFunction);
  }, []);

  // Function to render Right Controller
  const renderRightController = useCallback(() => {
    const rightButton = (
      <ArrowIcon
        customTop={customTop}
        color={controlsColor}
        direction="right"
        flex={flex}
        onClick={setContainerRightPosition}
        className="calendarSection__arrowIcon calendarSection__arrowIcon--right"
      >
        <ArrowIconRight height={10} src={ArrowIconSVG} alt="right arrow" />
      </ArrowIcon>
    );

    if (showControls && !isTouch) {
      if (controlsOnHover) {
        if (hover && containerPosition < containerMaxWidth && flex) {
          return rightButton;
        } else if (!flex && hover) {
          return rightButton;
        }
      } else {
        if (containerPosition < containerMaxWidth && flex) {
          return rightButton;
        } else if (!flex) {
          return rightButton;
        }
      }
    }
    return null;
  }, [
    showControls,
    controlsColor,
    customTop,
    setContainerRightPosition,
    isTouch,
    containerPosition,
    containerMaxWidth,
    flex,
    controlsOnHover,
    hover,
  ]);

  // Function to render Left Controller
  const renderLeftController = useCallback(() => {
    const leftButton = (
      <ArrowIcon
        customTop={customTop}
        color={controlsColor}
        flex={flex}
        onClick={setContainerPositionLeft}
        direction="left"
        className="calendarSection__arrowIcon calendarSection__arrowIcon--left"
      >
        <ArrowIconLeft height={10} src={ArrowIconSVG} alt="right arrow" />
      </ArrowIcon>
    );
    if (showControls && !isTouch) {
      if (controlsOnHover) {
        if (hover && containerPosition > 0 && flex) {
          return leftButton;
        } else if (!flex && hover) {
          return leftButton;
        }
      } else {
        if (containerPosition > 0 && flex) {
          return leftButton;
        } else if (!flex) {
          return leftButton;
        }
      }
    }
    return null;
  }, [
    showControls,
    isTouch,
    containerPosition,
    flex,
    controlsOnHover,
    hover,
    controlsColor,
    customTop,
    setContainerPositionLeft,
  ]);

  console.log(containerRef.current, "container Ref");

  return (
    <>
      <Container
        biggerContainer={biggerContainer}
        anim={anim}
        fullWidth={fullWidth}
        biggerZIndex={biggerZIndex}
        overflow={overflow}
        className={className && className}
        noBorder={noBorder}
        backgroundColor={backgroundColor}
        height={height}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {renderRightController()}
        {renderLeftController()}

        {title && <Title titleVarian={titleVarian}>{title}</Title>}
        {flex ? (
          <FlexInner
            className={customInnerClassNames}
            wrap={wrap}
            overflow={overflow}
            ref={containerRef}
            onScroll={onScollHandler}
          >
            {children}
          </FlexInner>
        ) : (
          children
        )}
      </Container>
    </>
  );
};
