import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ContactElement } from "../Components/ContactElement/ContactElement";
import { StyledContainer } from "../Components/StyledContainer/StyledContainer";
import Bg from "../static/images/aboutBG.png";
import Wave from "../static/images/icons/fala główna strona.png";
import SmallWave from "../static/images/icons/smallWave.png";

import About1Icon from "../static/images/icons/About1.png";
import About2Icon from "../static/images/icons/About2.png";
import About3Icon from "../static/images/icons/About3.png";
import About4Icon from "../static/images/icons/About4.png";
import About5Icon from "../static/images/icons/About5.png";

import BgSmall from "../static/images/AboutMobile.png";

import BgMedium from "../static/images/about (1800).png";

interface AboutPageProps {
  loaded: boolean;
  loadedProps: boolean;
}

const StyledSpan = styled.span`
  font-weight: 600;
  line-height: 1.2;
`;

const AboutText = styled.p`
  margin: 1.5rem auto 0 auto;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: justify;

  @media screen and (max-width: 1200px) {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 900px) {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 1.5rem;
    width: 95%;
    font-size: 1rem;
  }

  @media screen and (max-width: 380px) {
    font-size: 0.8rem;
  }
`;

export const AboutPage: React.FC<AboutPageProps> = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  /* Detect width change */
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 300);
  }, []);

  useEffect(() => {
    if (loaded) {
      if (containerRef.current) {
        containerRef.current.classList.remove("preload-fix");
      }
    } else {
      if (containerRef.current) {
        containerRef.current.classList.remove("preload-fix");
      }
    }
  }, [loaded]);

  return (
    <>
      {loaded ? (
        <>
          <div ref={containerRef} className="w-100 preload-fix animated">
            <img
              alt="background"
              className="animated w-100"
              src={width < 800 ? BgSmall : width <= 1800 ? BgMedium : Bg}
            />
            <div className="d-flex align-items-center justify-content-center">
              {width > 900 ? (
                <AboutText>
                  Jesteśmy młodym, energicznym zespołem który jest chętny do
                  pracy
                  <br /> i nie boi się wyzwań. Przez ostatnie 5 lat zbieraliśmy
                  doświadczenie <br /> sprzątając i konserwując łodzie w
                  największych Trójmiejskich marinach.
                </AboutText>
              ) : (
                <AboutText>
                  Jesteśmy młodym, energicznym zespołem <br /> który jest chętny
                  do pracy i nie boi się wyzwań. <br /> Przez ostatnie 5 lat
                  zbieraliśmy doświadczenie <br /> sprzątając i konserwując
                  łodzie w największych <br /> Trójmiejskich marinach.
                </AboutText>
              )}
            </div>

            <img
              className="w-100 animated"
              alt="wave"
              src={width < 1000 ? SmallWave : Wave}
            />
          </div>

          <StyledContainer className="animated mb-5" noBorder>
            <div className="d-flex justify-content-around mb-5 flex-column flex-xs-column flex-md-row text-center">
              <ContactElement noMargin title="" icon={About1Icon}>
                <StyledSpan>Szeroki wachlarz usług</StyledSpan>
              </ContactElement>
              <ContactElement title="" icon={About2Icon}>
                <StyledSpan>Ponad 5 letnie doświadczenie</StyledSpan>
              </ContactElement>
              <ContactElement title="" icon={About3Icon}>
                <StyledSpan>
                  Dokładność - dbamy <br /> o najdrobniejsze detale
                </StyledSpan>
                {/* <StyledSpan></StyledSpan> */}
              </ContactElement>
              <ContactElement title="" icon={About4Icon}>
                <StyledSpan>Przystępne ceny</StyledSpan>
              </ContactElement>
              <ContactElement title="" icon={About5Icon}>
                <StyledSpan>Elastyczne terminy</StyledSpan>
              </ContactElement>
            </div>
          </StyledContainer>
        </>
      ) : (
        <div className="preload-fix"></div>
      )}
    </>
  );
};
