import React, { useEffect } from "react";
// import styled from "styled-components";
import { OfferElementBig } from "../Components/OfferElementBig/OfferElementBig";
import { OffersModule } from "../Components/OffersModule/OffersModule";
import { StyledContainer } from "../Components/StyledContainer/StyledContainer";
import Offer1Big from "../static/images/Offers/1/1.jpg";
import Offer1Medium from "../static/images/Offers/1/2.jpg";
import Offer1Small from "../static/images/Offers/1/3.jpg";

import Offer2Big from "../static/images/Offers/2/1.jpg";
import Offer2Medium from "../static/images/Offers/2/2.jpg";
import Offer2Small from "../static/images/Offers/2/3.jpg";

import Offer3Big from "../static/images/Offers/3/1.jpg";
import Offer3Medium from "../static/images/Offers/3/2.jpg";
import Offer3Small from "../static/images/Offers/3/3.jpg";

import Offer4Big from "../static/images/Offers/4/1.jpg";
import Offer4Medium from "../static/images/Offers/4/2.jpg";
import Offer4Small from "../static/images/Offers/4/3.jpg";

import Offer5Big from "../static/images/Offers/5/1.jpg";
import Offer5Medium from "../static/images/Offers/5/2.jpg";
import Offer5Small from "../static/images/Offers/5/3.jpg";

import Offer6Big from "../static/images/Offers/6/1.jpg";
import Offer6Medium from "../static/images/Offers/6/2.jpg";
import Offer6Small from "../static/images/Offers/6/3.jpg";

import Offer7Big from "../static/images/Offers/7/1.jpg";
import Offer7Medium from "../static/images/Offers/7/2.jpg";
import Offer7Small from "../static/images/Offers/7/3.jpg";

import Offer8Big from "../static/images/Offers/8/1.jpg";
import Offer8Medium from "../static/images/Offers/8/2.jpg";
import Offer8Small from "../static/images/Offers/8/3.jpg";

import Offer9Big from "../static/images/Offers/9/1.jpg";
import Offer9Medium from "../static/images/Offers/9/2.jpg";
import Offer9Small from "../static/images/Offers/9/3.jpg";

import Offer10Big from "../static/images/Offers/10/1.jpg";
import Offer10Medium from "../static/images/Offers/10/2.jpg";
import Offer10Small from "../static/images/Offers/10/3.jpg";

import Offer11Big from "../static/images/Offers/11/1.jpg";
import Offer11Medium from "../static/images/Offers/11/2.jpg";
import Offer11Small from "../static/images/Offers/11/3.jpg";

import Offer12Big from "../static/images/Offers/12/1.jpg";
import Offer12Medium from "../static/images/Offers/12/2.jpg";
import Offer12Small from "../static/images/Offers/12/3.jpg";

import CzyszczenieDrzewa1 from "../static/images/Czyszczenie drewna teakowego/1.jpg";
import CzyszczenieDrzewa2 from "../static/images/Czyszczenie drewna teakowego/2.jpg";
import CzyszczenieDrzewa3 from "../static/images/Czyszczenie drewna teakowego/3.jpg";
import CzyszczenieDrzewa4 from "../static/images/Czyszczenie drewna teakowego/4.jpg";
import CzyszczenieDrzewa5 from "../static/images/Czyszczenie drewna teakowego/5.jpg";
import CzyszczenieDrzewa6 from "../static/images/Czyszczenie drewna teakowego/6.jpg";
import CzyszczenieDrzewa7 from "../static/images/Czyszczenie drewna teakowego/7.jpg";
import CzyszczenieDrzewa8 from "../static/images/Czyszczenie drewna teakowego/8.jpg";
import CzyszczenieDrzewa9 from "../static/images/Czyszczenie drewna teakowego/9.jpg";
import CzyszczenieDrzewa10 from "../static/images/Czyszczenie drewna teakowego/10.jpg";
import CzyszczenieDrzewa11 from "../static/images/Czyszczenie drewna teakowego/11.jpg";
import CzyszczenieDrzewa12 from "../static/images/Czyszczenie drewna teakowego/12.jpg";
import CzyszczenieDrzewa13 from "../static/images/Czyszczenie drewna teakowego/13.jpg";
import CzyszczenieDrzewa14 from "../static/images/Czyszczenie drewna teakowego/14.jpg";

import Odbijaczy1 from "../static/images/Czyszczenie odbijaczy/1.jpg";

import CzyszczenieZewnatrz1 from "../static/images/Czyszczenie jachtu z zewnątrz/1.jpg";
import CzyszczenieZewnatrz2 from "../static/images/Czyszczenie jachtu z zewnątrz/2.jpg";
import CzyszczenieZewnatrz3 from "../static/images/Czyszczenie jachtu z zewnątrz/3.jpg";
import CzyszczenieZewnatrz4 from "../static/images/Czyszczenie jachtu z zewnątrz/4.jpg";
import CzyszczenieZewnatrz5 from "../static/images/Czyszczenie jachtu z zewnątrz/5.jpg";
import CzyszczenieZewnatrz6 from "../static/images/Czyszczenie jachtu z zewnątrz/6.jpg";
import CzyszczenieZewnatrz7 from "../static/images/Czyszczenie jachtu z zewnątrz/7.jpg";
import CzyszczenieZewnatrz8 from "../static/images/Czyszczenie jachtu z zewnątrz/8.jpg";

import CzyszczenieTapicerek1 from "../static/images/Czyszczenie tapicerek/1.jpg";
import CzyszczenieTapicerek2 from "../static/images/Czyszczenie tapicerek/2.jpg";
import CzyszczenieTapicerek3 from "../static/images/Czyszczenie tapicerek/3.jpg";

import CzyszczenieWewnatrz1 from "../static/images/Czyszczenie wewnątrz/1.jpg";

import ProjektowanieZamowienie1 from "../static/images/Projektowanie Grafik na zamówienie/1.jpg";
import ProjektowanieZamowienie2 from "../static/images/Projektowanie Grafik na zamówienie/2.jpg";

import PrzygotowanieNaZime1 from "../static/images/Przygotowanie jachtu na zime/1.jpg";
import PrzygotowanieNaZime2 from "../static/images/Przygotowanie jachtu na zime/2.jpg";
import PrzygotowanieNaZime3 from "../static/images/Przygotowanie jachtu na zime/3.jpg";
import PrzygotowanieNaZime4 from "../static/images/Przygotowanie jachtu na zime/4.jpg";
import PrzygotowanieNaZime5 from "../static/images/Przygotowanie jachtu na zime/5.jpg";

interface OffersPageProps {}

export const OffersPage: React.FC<OffersPageProps> = () => {
  useEffect(() => {
    if (window.location.hash) {
      document
        .querySelector(window.location.hash)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <StyledContainer className="animated" noBorder>
      <div className="mt-5">
        <OffersModule noButton />
      </div>
      <div className="mt-5">
        <OfferElementBig
          direction="left"
          id="czyszczenie-zewnetrza-jachtu"
          title1="Czyszczenie jachtu"
          title2="z zewnątrz"
          text1="Od kilku do kilkunastu razy w sezonie, w zależności od częstotliwości użytkowania. "
          text2={`Utrzymywanie łodzi w czystości jest najważniejszą formą konserwacji. Nie jest to wyłącznie kwestia wyglądu i przyjemności użytkowania. Im dłużej dane zabrudzenia znajdują się na powierzchni, tym trudniejsze będą do usunięcia. Brud, na przykład w postaci ptasich odchodów, ingeruje w strukturę laminatu oraz drewna powodując przebarwienia. Żelkot, który nie jest utrzymywany w czystości, staje się trwale odbarwiony, a zalegający brud wewnątrz łodzi może dostać się do pomp, odpływów, zawiasów i innych ruchomych części, powodując uszkodzenia.`}
          text3={`Do mycia pokładu, kokpitu, bakist oraz burt używamy biodegradowalnego szamponu. Natomiast linię wodną, szyby, elementy stalowe oraz zadaszenia czyścimy specjalistycznymi środkami dedykowanymi dla danych powierzchni.`}
          imageBig={Offer1Big}
          images={[
            CzyszczenieZewnatrz1,
            CzyszczenieZewnatrz2,
            CzyszczenieZewnatrz3,
            CzyszczenieZewnatrz4,
            CzyszczenieZewnatrz5,
            CzyszczenieZewnatrz6,
            CzyszczenieZewnatrz7,
            CzyszczenieZewnatrz8,
          ]}
          imageMid={Offer1Medium}
          imageSmall={Offer1Small}
        />

        <OfferElementBig
          direction="right"
          id="czyszczenie-wnetrza-jachtu"
          images={[CzyszczenieWewnatrz1]}
          title1="Czyszczenie jachtu"
          title2="wewnątrz"
          text1="Od kilku do kilkunastu razy w sezonie, w zależności od częstotliwości użytkowania jachtu."
          text2={`Regularne czyszczenie łodzi pozwala uniknąć powstawania ciężkich i trudnych do usunięcia zabrudzeń. Porządek ułatwia funkcjonowanie, a w szczególności sprawia, że spędzony na niej czas jest przyjemniejszy. `}
          text3={`Kompleksowo czyścimy wnętrze jachtu od sufitu aż po zęzy. Dokładnie czyścimy i odkurzamy każdy zakamarek, łącznie z kuchenką, lodówką, mikrofalą oraz oknami.`}
          imageBig={Offer2Big}
          imageMid={Offer2Medium}
          imageSmall={Offer2Small}
        />

        <OfferElementBig
          direction="left"
          id="czyszczenie-i-olejowanie-teaku"
          title1="Czyszczenie"
          title2="i olejowanie teaku"
          text1="2 lub 3 razy - na początku, w trakcie oraz na koniec sezonu."
          text2={`Drewno teakowe jest narażone na negatywne działanie wody morskiej, promieni słonecznych, grzybów oraz mechanicznych uszkodzeń. Przez brak regularnej konserwacji powierzchnia drewna szarzeje, ściera się, staje się nierównomierna oraz nabiera licznych przebarwień. Regularne czyszczenie oraz konserwacja olejem, zabezpiecza drewno przed szkodliwym działaniem wyżej wymienionych czynników oraz nadaje mu piękny i zdrowy wygląd.`}
          text3={`Dokładnie oczyszczamy drewno z zabrudzeń, pozbywając się szarej barwy, grzybów oraz resztek starego oleju. Przed olejowaniem precyzyjnie zabezpieczamy wszystkie powierzchnie narażone na zabrudzenia. Ostatnim i najważniejszym etapem jest nakładanie oleju, do momentu pełnego nasycenia struktury drewna, przywracając mu naturalny koloryt.`}
          imageBig={Offer3Big}
          imageMid={Offer3Medium}
          imageSmall={Offer3Small}
          images={[
            CzyszczenieDrzewa1,
            CzyszczenieDrzewa2,
            CzyszczenieDrzewa3,
            CzyszczenieDrzewa4,
            CzyszczenieDrzewa5,
            CzyszczenieDrzewa6,
            CzyszczenieDrzewa7,
            CzyszczenieDrzewa8,
            CzyszczenieDrzewa9,
            CzyszczenieDrzewa10,
            CzyszczenieDrzewa11,
            CzyszczenieDrzewa12,
            CzyszczenieDrzewa13,
            CzyszczenieDrzewa14,
          ]}
        />

        <OfferElementBig
          direction="right"
          id="polerowanie-oraz-woskowanie-laminatu"
          title1="Polerowanie"
          title2="i woskowanie laminatu"
          text1="Najlepiej wykonywać je 1 a czasem nawet 2 razy w sezonie."
          text2={`Polerowanie odnawia laminat i przywraca mu dawny połysk, usuwając zmatowioną powierzchnię pozbywając się przebarwień i zarysowań, ujednolicając koloryt. Natomiast woskowanie tworzy warstwę ochronną oraz zapobiega przywieraniu brudu.`}
          text3={`Polerowanie wykonujemy mechanicznie oraz ręcznie w trudno dostępnych miejscach.
        W zależności od zabrudzeń, uszkodzeń i twardości podłoża dobieramy stopień ścieralności pasty polerskiej. Następnie ruchami kolistymi nakładamy wosk.
        `}
          imageBig={Offer4Big}
          imageMid={Offer4Medium}
          imageSmall={Offer4Small}
        />

        <OfferElementBig
          direction="left"
          id="czyszczenie-oraz-impregnacja-powierzchni-skorzanych"
          title1="Czyszczenie"
          title2="i impregnacja skóry"
          text1={`
        Skóry pod zadaszeniem - 1 raz.
        Skóry zewnętrzne - kilka razy w sezonie.
        `}
          text2={`Powierzchnie skórzane należy regularnie czyścić w celu uniknięcia trwałych przebarwień oraz wnikania zabrudzeń w głąb struktury skóry. Impregnacja nawilża skórę i zapobiega jej pękaniu, tworząc powłokę ochronną na jej powierzchni.`}
          text3={`Skóry czyścimy naturalnymi środkami za pomocą szczotki wykonanej z miękkiego włosia, wykonując koliste ruchy. Wyczyszczoną powierzchnię pokrywamy nawilżającym preparatem impregnującym.
        `}
          imageBig={Offer5Big}
          imageMid={Offer5Medium}
          imageSmall={Offer5Small}
        />
        <OfferElementBig
          direction="right"
          id="odbijacze"
          title1="Czyszczenie"
          title2="odbijaczy"
          images={[Odbijaczy1]}
          text1="Od kilku do kilkunastu razy w sezonie."
          text2={`Odbijacze chroniąc burty wykonują trudne zadanie, które wiąże się z ciągłym ocieraniem o nabrzeże powodując liczne zabrudzenia.`}
          text3={`Dzięki specjalistycznej chemii pozbywamy się resztek śladów gum i innych zabrudzeń, aby nawet najjaśniejszy odbijacz wyglądał jak nowy.
        `}
          imageBig={Offer6Big}
          imageMid={Offer6Medium}
          imageSmall={Offer6Small}
        />
        <OfferElementBig
          direction="left"
          id="czyszczenie-i-konserwacja-powierzchni-stalowych"
          title1="Czyszczenie"
          title2="i konserwacja stali"
          text1="Od jednego do kilku razy w sezonie."
          text2={`Jedynie regularne czyszczenie pozwala utrzymać metaliczny połysk i zapobiega powstawaniu korozji.`}
          text3={`Dzięki specjalistycznej chemii kolejno czyścimy, polerujemy oraz zabezpieczamy powierzchnie stalowe.
        `}
          imageBig={Offer7Big}
          imageMid={Offer7Medium}
          imageSmall={Offer7Small}
        />

        <OfferElementBig
          direction="right"
          id="czyszczenie-tapicerek-i-dywanow"
          title1="Czyszczenie tapicerek"
          images={[
            CzyszczenieTapicerek1,
            CzyszczenieTapicerek2,
            CzyszczenieTapicerek3,
          ]}
          title2="i dywanów"
          text1="Najlepiej wykonywać je 1 a czasem nawet 2 razy w sezonie."
          text2={`Różne elementy materiałowe z czasem ulegają zabrudzeniom. W celu zachowania świeżego i estetycznego wyglądu warto zdecydować się na ich dokładne czyszczenie.`}
          text3={`Plamy na dywanie, wykładzinie, tapicerce jachtowej lub meblowej, zostaną przez nas wywabione w sposób bezpieczny i skuteczny.
        `}
          imageBig={Offer8Big}
          imageMid={Offer8Medium}
          imageSmall={Offer8Small}
        />

        <OfferElementBig
          direction="left"
          id="szycie-elementow-materialowych"
          title1="Szycie elementów"
          title2=" materiałowych"
          text2={`Przygotowujemy spersonalizowane obicia materacy, poduszki, zasłony, obrusy, ścierki i inne elementy materiałowe.`}
          text3={`Sporządzamy inwentaryzację i zbieramy wymiary poszczególnych elementów, przygotowujemy zestawienie kolorystyczne i materiałowe w celu nadania indywidualnego charakteru wnętrza jachtu.`}
          imageBig={Offer9Big}
          imageMid={Offer9Medium}
          imageSmall={Offer9Small}
        />

        <OfferElementBig
          direction="right"
          id="przygotowanie-jachtu-na-zime"
          title1="Przygotowanie jachtu "
          title2="do sezonu"
          text2={`Po około 6-miesięcznym okresie zimowania jachtu należy go dokładnie doczyścić i przygotować na nowy sezon. Jeśli zaczniemy sezon od gruntownych porządków, pozwoli to Państwu na pełne cieszenie się łodzią od pierwszego cieplejszego dnia. `}
          text3={`Na koniec każdego sezonu myjemy jacht, dokładnie osuszamy oraz wietrzymy wnętrza. Przykrywamy go plandeką w taki sposób aby zapewnić swobodny przepływ powietrza pod nią, co zapobiega rozwojowi grzybów i bakterii. Plandekę zabezpieczamy przed zerwaniem przez wiatr oraz przed ciężarem zalegającego na niej śniegu.`}
          imageBig={Offer10Big}
          imageMid={Offer10Medium}
          imageSmall={Offer10Small}
        />

        <OfferElementBig
          direction="left"
          id="przygotowanie-jachtu-do-sezonu"
          title1="Przygotowanie jachtu"
          title2="na zimę"
          images={[
            PrzygotowanieNaZime1,
            PrzygotowanieNaZime2,
            PrzygotowanieNaZime3,
            PrzygotowanieNaZime4,
            PrzygotowanieNaZime5,
          ]}
          text2={`Okres zimowania jachtu w Polsce trwa około 6 miesięcy, z tego względu należy zabezpieczyć łódź przed negatywnym działaniem mrozu, śniegu, kurzu, opadających liści oraz zwierząt.`}
          text3={`Swoją pracę przeważnie zaczynamy od zdejmowania plandeki. Następnie wietrzymy wnętrze jachtu i zabieramy się za gruntowne porządki każdego zakamarka łodzi wewnątrz i zewnątrz łącznie z zęzami. `}
          imageBig={Offer11Big}
          imageMid={Offer11Medium}
          imageSmall={Offer11Small}
        />

        <OfferElementBig
          direction="right"
          id="projekty-spersonalizowanych-grafik-dla-calej-zalogi"
          title1="Projekt grafik"
          title2="na zamówienie"
          images={[ProjektowanieZamowienie1, ProjektowanieZamowienie2]}
          text2={`Przygotowujemy spersonalizowane projekty graficzne, które mogą być umieszczone na dowolnych przedmiotach, np. na: koszulkach, czapkach, bluzach, kubkach czy poduszkach.`}
          text3={`Zapewniamy możliwość konsultacji projektu, tak aby jak najlepiej sprostać Państwa oczekiwaniom. Grafiki wykonujemy własnoręcznie przy użyciu farb do tkanin lub za pomocą wydruku.`}
          customTitle2="Projekt"
          customTitle3="Realizacja"
          imageBig={Offer12Big}
          imageMid={Offer12Medium}
          imageSmall={Offer12Small}
        />
      </div>
    </StyledContainer>
  );
};
