import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledContainer } from "../StyledContainer/StyledContainer";
import { NavItem } from "./NavItem";
// import { NavItem } from "./NavItem";
import LogoIcon from "../../static/images/icons/logo belka gorna.png";

import aboutIcon from "../../static/images/icons/o nas.png";
import contactIcon from "../../static/images/icons/kontakt.png";
import offersIcon from "../../static/images/icons/oferta.png";

interface NavbarProps {}

interface StyledNavigationProps {
  grey: boolean;
}
const Navigation = styled.nav<StyledNavigationProps>`
  ${({ grey }) =>
    grey
      ? `background-color:#343434`
      : `
      background-color:rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  `};

  width: 100%;
  z-index: 1000;
  padding: 0.5rem;
  border-bottom: 1px solid white;
`;

const NavigationInfo = styled.div`
  flex: 0.35;

  @media screen and (max-width: 997px) {
    flex: 0.44;
  }

  @media screen and (max-width: 768px) {
    text-align: left;
    flex: 0.6;
  }

  @media screen and (max-width: 576px) {
    flex: 0.8;
  }

  @media screen and (max-width: 500px) {
    flex: 1;
    min-width: 100%;
    text-align: center;
  }
`;

const Logo = styled(Link)`
  flex: 0.65;
  text-align: left;

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const StyledLogoIcon = styled.img`
  width: 250px;
  object-fit: contain;
  @media screen and (max-width: 800px) {
    width: 150px;
  }
`;

const Upper = styled.div`
  flex: 1;
`;

export const Navbar: React.FC<NavbarProps> = () => {
  const grey = window.location.pathname !== "/";

  return (
    <Navigation grey={grey} className="animated">
      <StyledContainer
        className="mx-auto d-flex flex-column flex-wrap"
        noBorder
      >
        <Upper className="d-flex align-items-center justify-centent-space-between py-2 px-2 flex-wrap">
          <Logo to="/">
            <StyledLogoIcon src={LogoIcon} alt="logo" />
          </Logo>

          <NavigationInfo className="d-flex justify-content-between align-items-center">
            <NavItem title="Oferta" link="/offers" icon={offersIcon} />
            <NavItem title="O Nas" link="/about" icon={aboutIcon} />
            <NavItem title="Kontakt" link="/contact" icon={contactIcon} />
          </NavigationInfo>
        </Upper>
      </StyledContainer>
    </Navigation>
  );
};
