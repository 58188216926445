import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface FooterItemProps {
  title: string;
  link: string;
  noHover?: boolean;
}

interface LinkProps {
  noHover?: boolean;
}
const StyledLink = styled(Link)<LinkProps>`
  color: white !important;
  transition: all 0.2s ease;
  text-transform: uppercase;
  text-decoration: none !important;
  white-space: nowrap;
  font-weight: bold;

  ${({ noHover }) =>
    !noHover &&
    `
  &:hover {
    opacity: 0.5;
  }
  `};
`;

export const FooterItem: React.FC<FooterItemProps> = ({
  title,
  link,
  noHover,
}) => {
  return (
    <StyledLink noHover={noHover} to={link}>
      {title}
    </StyledLink>
  );
};
