import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";

interface FooterColumnProps {
  title: string;
  xl: number;
  lg: number;
  md: number;
  sm: number;
  xs: number;
  className?: string;
  noBorder?: boolean;
}

interface CustomColProps {
  noBorder?: boolean;
}

const CustomCol = styled(Col)<CustomColProps>`
  color: #282828;
  text-align: center;
  position: relative;
  ${({ noBorder }) =>
    noBorder &&
    `
&:after{
    display: none;
}
`};
  @media screen and (max-width: 574px) {
    &:after {
      content: "";
      height: 1px;
      width: 50%;
      position: absolute;
      background-color: grey;
      left: 50%;
      bottom: -5px;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  @media screen and (max-width: 400px) {
    &:after {
      width: 70%;
    }
  }
`;

const Title = styled.h3`
  font-size: 1.1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

export const FooterColumn: React.FC<FooterColumnProps> = ({
  title = "",
  children,
  xl,
  md,
  sm,
  lg,
  xs,
  className,
  noBorder,
}) => {
  return (
    <CustomCol
      noBorder={noBorder}
      xs={xs}
      xl={xl}
      md={md}
      sm={sm}
      lg={lg}
      //   order={order}
      className={className && className}
    >
      <Title>{title}</Title>
      {children}
    </CustomCol>
  );
};
