import React from "react";
import styled from "styled-components";

interface ContactElementProps {
  title: string;
  icon: string;
  noMargin?: boolean;
}

const Icon = styled.img`
  height: 150px;
  object-fit: contain;

  @media screen and (max-width: 900px) {
    height: 100px;
  }
`;

interface InnerProps {
  noMargin?: boolean;
}

const Inner = styled.div<InnerProps>`
  flex: 0.33;
  @media screen and (max-width: 764px) {
    flex: 1;
    ${({ noMargin }) => !noMargin && `margin-top: 1.5rem`}
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 2rem;
  @media screen and (max-width: 800px) {
    font-size: 1.4rem;
  }
`;
export const ContactElement: React.FC<ContactElementProps> = ({
  title,
  icon,
  children,
  noMargin,
}) => {
  return (
    <Inner
      noMargin={noMargin}
      className="d-flex flex-column align-items-center"
    >
      <Icon alt={title} src={icon} />
      <Title>{title}</Title>
      {children}
    </Inner>
  );
};
