import React from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledContainer } from "../StyledContainer/StyledContainer";
import { FooterColumn } from "./FooterColumn";
import { FooterItem } from "./FooterItem";
import FooterLogo from "../../static/images/icons/logo belka dolna.png";
import ArrowIcon from "../../static/images/icons/angle Arrow.png";

interface FooterProps {}

const StyledFooter = styled.footer`
  background-color: #343434;
  padding: 3rem 0;
  max-width: 100%;
  @media screen and (max-width: 1110px) {
    padding: 3rem 1rem;
  }

  @media screen and (max-width: 992px) {
    padding: 2rem 1rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    text-align: center;
  }
`;

const StyledLink = styled.a`
  color: white !important;
  transition: all 0.2s ease;
  text-decoration: none !important;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledSpan = styled.span`
  display: block;
`;

const LogoIcon = styled.img`
  width: 50%;
  object-fit: contain;
  @media screen and (max-width: 500px) {
    width: 85%;
  }
`;

const InfoText = styled.span`
  margin-top: 1rem;
  color: white;
  text-transform: uppercase;
  font-size: 11px;
`;

const FooterInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 60%;

  @media screen and (max-width: 1280px) {
    width: 85%;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  @media screen and (max-width: 765px) {
    width: 85%;
  }
`;

const ArrowWrapper = styled.div`
  border: 1px solid #9a9a9a;
  padding: 1rem;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  &:active {
    transform: scale(0.9);
  }

  @media screen and (max-width: 764px) {
    position: absolute;
    right: 5%;
  } ;
`;
const ArrowStyled = styled.img`
  transform: rotate(90deg) scale(0.4);
`;

export const Footer: React.FC<FooterProps> = () => {
  const emailShop = "daw.chylinski@gmail.com";
  const phoneNumber = "+48 795 422 542";

  /* Scroll up handler */
  const scrollUpHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <StyledFooter>
      <StyledContainer noBorder>
        {/* First Row */}
        <Row>
          <FooterColumn
            noBorder
            className="mb-4 mb-sm-4 mb-lg-0 order-1 order-xs-1 order-md-1"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            title=""
          >
            <FooterInner>
              <FooterItem title="oferta" link="/offers" />
              <FooterItem title="O Nas" link="/about" />
              <FooterItem title="Kontakt" link="/contact" />
            </FooterInner>
          </FooterColumn>

          <FooterColumn
            noBorder
            className="mb-4 mb-sm-4 mb-lg-0 order-3 order-xs-3 order-md-2"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            title=""
          >
            <div className="d-flex flex-column align-items-center">
              <LogoIcon className="d-block" src={FooterLogo} alt="logo" />
              <InfoText>
                {new Date().getFullYear()} | strona zbudowana przez spektrum it
              </InfoText>
            </div>
          </FooterColumn>

          <FooterColumn
            className="mb-4 mb-sm-4 mb-lg-0 order-2 order-xs-2 order-md-3"
            noBorder
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            title=""
          >
            <FooterItem noHover title="Kontakt" link="#" />
            <StyledSpan>
              <StyledLink href={`mailto:${emailShop}`}>{emailShop}</StyledLink>
            </StyledSpan>
            <StyledSpan>
              <StyledLink href={`tel:+48${phoneNumber.trim()}`}>
                {phoneNumber}
              </StyledLink>
            </StyledSpan>
            <ArrowWrapper onClick={scrollUpHandler}>
              <ArrowStyled alt="arrow up" src={ArrowIcon} />
            </ArrowWrapper>
          </FooterColumn>
        </Row>
      </StyledContainer>
    </StyledFooter>
  );
};
