import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ContactElement } from "../Components/ContactElement/ContactElement";
import { StyledContainer } from "../Components/StyledContainer/StyledContainer";
import Bg from "../static/images/ContactBG.png";
import contactHand from "../static/images/icons/contactHand.png";
import contactMail from "../static/images/icons/contactMail.png";
import contactPhone from "../static/images/icons/contactPhone.png";
import Wave from "../static/images/icons/fala główna strona.png";
import SmallWave from "../static/images/icons/smallWave.png";

import BgSmall from "../static/images/contactMobile.png";
import BgMedium from "../static/images/Contact (1600).png";

interface ContactPageProps {
  loaded: boolean;
  loadedProps: boolean;
}

const StyledLink = styled.a`
  color: black !important;
  text-decoration: none !important;
  font-weight: 600;
  &:hover {
    opacity: 0.5;
  }
`;

const StyledSpan = styled.span`
  font-weight: 600;
`;

export const ContactPage: React.FC<ContactPageProps> = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  /* Detect width change */
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 300);
  }, []);

  useEffect(() => {
    if (loaded) {
      if (containerRef.current) {
        containerRef.current.classList.remove("preload-fix");
      }
    } else {
      if (containerRef.current) {
        containerRef.current.classList.remove("preload-fix");
      }
    }
  }, [loaded]);

  const emailShop = "daw.chylinski@gmail.com";
  const phoneNumber = "+48 795 422 542";
  return (
    <>
      {loaded ? (
        <>
          <div ref={containerRef} className="w-100 preload-fix animated">
            <img
              alt="background"
              className="animated w-100"
              src={width < 800 ? BgSmall : width <= 1600 ? BgMedium : Bg}
            />
            <img
              className="w-100 animated"
              alt="wave"
              src={width < 1000 ? SmallWave : Wave}
            />
          </div>

          <StyledContainer className="animated" noBorder>
            <div className="d-flex justify-content-around mb-5 flex-column flex-xs-column flex-md-row text-center">
              <ContactElement noMargin title="Kontakt" icon={contactPhone}>
                <StyledLink href={`tel:+48${phoneNumber.trim()}`}>
                  {phoneNumber}
                </StyledLink>
              </ContactElement>
              <ContactElement title="Email" icon={contactMail}>
                <StyledLink href={`mailto:${emailShop}`}>
                  {emailShop}
                </StyledLink>
              </ContactElement>
              <ContactElement title="O firmie" icon={contactHand}>
                <StyledSpan>
                  Brand Yacht Cleaning jest częścią firmy:
                </StyledSpan>
                <StyledSpan>Spektrum Dawid Chyliński</StyledSpan>
                <StyledSpan>ul. Rybacka 14 Grudziądz</StyledSpan>
                <StyledSpan>NIP: 8762495441</StyledSpan>
              </ContactElement>
            </div>
          </StyledContainer>
        </>
      ) : (
        <div className="preload-fix"></div>
      )}
    </>
  );
};
