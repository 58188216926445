import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SmallWave from "../../static/images/icons/smallWave.png";
import GelleryIcon from "../../static/images/icons/ikonka galerii.png";
import { Backdrop } from "../Backdrop/Backdrop";

interface OfferElementBigProps {
  title1: string;
  title2: string;
  text1?: string;
  text2: string;
  text3: string;
  imageBig: string;
  imageMid: string;
  imageSmall: string;
  id: string;
  customTitle2?: string;
  customTitle3?: string;
  direction: "left" | "right";
  images?: string[];
}

interface styledProps {
  direction: "left" | "right";
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.5rem;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
`;

const Left = styled.div<styledProps>`
  flex: 0.6;

  ${({ direction }) => (direction === "right" ? "order: 2" : "order: 1")};

  @media screen and (max-width: 1200px) {
    flex: 0.55;
  }

  @media screen and (max-width: 900px) {
    order: 2;
  }
`;

const Right = styled.div<styledProps>`
  flex: 0.4;
  flex-direction: column;
  display: flex;
  justify-content: center;
  ${({ direction }) =>
    direction === "right"
      ? "align-items: flex-end"
      : "align-items: flex-start"};

  ${({ direction }) => (direction === "right" ? "order: 1" : "order: 2")};

  @media screen and (max-width: 1200px) {
    flex: 0.45;
  }
  img {
    width: 95%;
  }

  @media screen and (max-width: 900px) {
    order: 1;
    align-items: center;
    margin-bottom: 1rem;
    img {
      width: 75%;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  margin: 0;
  font-size: 1.8rem;
  text-transform: uppercase;

  @media screen and (max-width: 750px) {
    font-size: 1.7rem;
  }
`;

const Subtitle = styled.h3<styledProps>`
  color: #ba957b;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -6.7%;

    background-color: #626262;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  @media screen and (max-width: 1200px) {
    &:before {
      left: -6.8%;
    }
  }

  @media screen and (max-width: 900px) {
    &:before {
      left: -5.1%;
    }
  }

  @media screen and (max-width: 750px) {
    font-size: 1.4rem;
    &:before {
      left: -5.2%;
    }
  }
  @media screen and (max-width: 500px) {
    text-align: center;
    &:before {
      left: -5.4%;
    }
  }

  @media screen and (max-width: 400px) {
    &:before {
      left: -5.8%;
    }
  }
`;

const Text = styled.p`
  text-indent: 10px;
  text-align: justify;
  line-height: 1.2;
  margin-bottom: 2rem;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

interface InnerProps {
  direction: "left" | "right";
}
const Inner = styled.div<InnerProps>`
  width: 80%;
  ${({ direction }) =>
    direction === "left" ? "margin: 0 auto 0 8%" : "margin: 0 4% 0 auto"};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    ${({ direction }) =>
      direction === "left" ? "margin: 0 auto 0 9%" : "margin: 0 5% 0 auto"};
  }

  @media screen and (max-width: 900px) {
    width: 75%;
    margin: 0 auto;
  }
`;

const WrapperLine = styled.div`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -6.1%;

    background-color: #626262;
    width: 2px;
    height: 95%;
  }

  @media screen and (max-width: 900px) {
    &:before {
      left: -4.7%;
    }
  }

  @media screen and (max-width: 550px) {
    &:before {
      height: 85%;
    }
  }

  @media screen and (max-width: 400px) {
    &:before {
      height: 90%;
    }
  }
`;

const WaveImage = styled.img`
  width: 105%;
  margin-left: -5%;
  margin-top: 1.5rem;
`;

const ButtonText = styled.p`
  margin: 0;
  margin-top: 1rem;
  transition: all 0.2s ease;
  &:hover {
    opacity: 0.5;
  }
`;

export const OfferElementBig: React.FC<OfferElementBigProps> = ({
  title1,
  title2,
  text1,
  text2,
  text3,
  imageBig,
  imageMid,
  imageSmall,
  id,
  customTitle2,
  customTitle3,
  direction,
  images,
}) => {
  /* Detect width change */
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const [show, setShow] = useState(false);

  return (
    <>
      <Container id={id}>
        <Left direction={direction}>
          <Inner direction={direction}>
            {width > 900 && (
              <div className="mb-3">
                <Title>{title1}</Title>
                <Title>{title2}</Title>
              </div>
            )}
            <WrapperLine>
              {text1 && (
                <>
                  <Subtitle direction={direction}>Jak często?</Subtitle>
                  <Text>{text1}</Text>
                </>
              )}
              <Subtitle direction={direction}>
                {customTitle2 ? customTitle2 : "Dlaczego?"}
              </Subtitle>
              <Text>{text2}</Text>
              <Subtitle direction={direction}>
                {customTitle3 ? customTitle3 : "Jak?"}
              </Subtitle>
              <Text>{text3}</Text>
            </WrapperLine>
            {images && (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setShow(true)}
                className="d-flex align-items-center"
              >
                <img
                  width={30}
                  height={30}
                  className="mr-3"
                  src={GelleryIcon}
                  alt="gallery"
                />
                <ButtonText>Zdjęcia z realizacji</ButtonText>
              </div>
            )}
            <WaveImage src={SmallWave} alt="wave" />
          </Inner>
        </Left>
        <Right direction={direction}>
          {width < 900 && (
            <div className="mb-3">
              <Title>{title1}</Title>
              <Title>{title2}</Title>
            </div>
          )}
          <Image
            src={width < 900 ? imageSmall : width < 1000 ? imageMid : imageBig}
            alt={title1 + title2}
          />
        </Right>
      </Container>
      {show && images && (
        <Backdrop images={images} setShow={setShow} show={show} />
      )}
    </>
  );
};
