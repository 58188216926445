import React, { useEffect } from "react";
import styled from "styled-components";
import { Slider } from "../Slider/Slider";

import CloseIcon from "../../static/images/icons/close.png";

interface BackdropProps {
  show: boolean;
  images: string[];
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}
const BackDrop = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
`;

const Close = styled.img`
  position: fixed;
  top: 1rem;
  right: 2rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const Backdrop: React.FC<BackdropProps> = ({
  show,
  setShow,
  images,
}) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);

  return (
    <BackDrop>
      <div className="mt-3">
        <Slider
          biggerSlider
          activeIndex={true}
          delay={5000}
          showControls={true}
          controlsOnHover={true}
          customClassNames="height-70vh"
          images={images}
        />
      </div>

      <Close src={CloseIcon} alt="close" onClick={() => setShow(false)}></Close>
    </BackDrop>
  );
};
