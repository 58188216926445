import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import { StyledContainer } from "../StyledContainer/StyledContainer";

interface SliderProps {
  images: string[];
  controlsOnHover?: boolean;
  delay: number | null;
  showControls: boolean;
  activeIndex?: boolean;
  biggerSlider?: boolean;
  customClassNames?: string;
}

const StyledCarousel = styled(Carousel)`
  height: 100%;
  @media screen (max-width: 900px) {
    min-height: 100vh;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;

  @media screen (max-width: 900px) {
    min-height: 100vh;
  }
`;

interface SmallImageProps {
  noMarginLeft?: boolean;
}

const SmallImage = styled.img<SmallImageProps>`
  height: 180px;
  width: 180px;
  object-fit: contain;
  margin-left: ${({ noMarginLeft }) => (noMarginLeft ? "0" : "2rem")};
  cursor: pointer;

  @media screen and (max-width: 600px) {
    height: 120px;
    width: 120px;
  }

  @media screen and (max-height: 400px) {
    height: 120px;
    width: 120px;
  }
`;

export const Slider: React.FC<SliderProps> = ({
  images,
  controlsOnHover,
  delay,
  showControls,
  activeIndex,
  biggerSlider,
  customClassNames,
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const unsubscibe = (e: any) => {
      if (e.keyCode === 37) {
        setIndex((prevState) => (prevState === 0 ? 0 : prevState - 1));
      }
      if (e.keyCode === 39) {
        setIndex((prevState) =>
          prevState + 1 >= images.length ? prevState : prevState + 1
        );
      }
    };
    window.addEventListener("keydown", unsubscibe);

    return () => window.removeEventListener("keypress", unsubscibe);
  }, [images.length]);

  useEffect(() => {
    const next = document.querySelector(".carousel-control-next");
    const prev = document.querySelector(".carousel-control-prev");

    const nextHandler = () => {
      setIndex((prevState) =>
        prevState + 1 >= images.length ? prevState : prevState + 1
      );
    };

    const prevHandler = () => {
      setIndex((prevState) => (prevState === 0 ? 0 : prevState - 1));
    };

    if (next && prev) {
      next?.addEventListener("click", nextHandler);
      prev?.addEventListener("click", prevHandler);
    }

    return () => {
      if (next && prev) {
        next?.removeEventListener("click", nextHandler);
        prev?.removeEventListener("click", prevHandler);
      }
    };
  }, [images.length]);

  return (
    <>
      <StyledContainer
        className={customClassNames}
        noBorder
        fullWidth={!biggerSlider}
        biggerContainer={biggerSlider}
        showControls={showControls}
        controlsOnHover={controlsOnHover}
      >
        {activeIndex ? (
          <StyledCarousel activeIndex={index} interval={delay}>
            {images.map((el, i) => (
              <Carousel.Item key={i}>
                <Image src={el} alt={`Slide ${i + 1}`} />
              </Carousel.Item>
            ))}
          </StyledCarousel>
        ) : (
          <StyledCarousel interval={delay}>
            {images.map((el, i) => (
              <Carousel.Item key={i}>
                <Image src={el} alt={`Slide ${i + 1}`} />
              </Carousel.Item>
            ))}
          </StyledCarousel>
        )}
      </StyledContainer>
      {activeIndex && (
        <StyledContainer
          customInnerClassNames="sliderSmallContainer justify-content-center"
          overflow
          showControls={true}
          controlsColor="grey"
          className="mt-3"
          biggerContainer
          flex={true}
          noBorder={true}
        >
          {images.map((el, i) => (
            <SmallImage
              noMarginLeft={i === 0 ? true : false}
              onClick={() => setIndex(i)}
              src={el}
              key={i}
              alt={`slide ${i + 1}`}
            />
          ))}
        </StyledContainer>
      )}
    </>
  );
};
