import React from "react";
import { StyledContainer } from "../StyledContainer/StyledContainer";

import Icon1 from "../../static/images/icons/łódka.png";
import Icon2 from "../../static/images/icons/sprayer.png";
import Icon3 from "../../static/images/icons/drewno.png";
import Icon4 from "../../static/images/icons/polerowanie.png";
import Icon5 from "../../static/images/icons/leather.png";
import Icon6 from "../../static/images/icons/odbijacz.png";
import Icon7 from "../../static/images/icons/koło sterowe.png";
import Icon8 from "../../static/images/icons/sofa.png";
import Icon9 from "../../static/images/icons/materiał.png";
import Icon10 from "../../static/images/icons/lato.png";
import Icon11 from "../../static/images/icons/zima.png";
import Icon12 from "../../static/images/icons/koszulki.png";
import { OfferElement } from "../OfferElement/OfferElement";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface OffersModuleProps {
  noButton?: boolean;
}

const Title = styled.h1`
  position: relative;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    background-color: black;
    width: 45%;
    height: 1px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    background-color: black;
    width: 45%;
    height: 1px;
  }

  @media screen and (max-width: 850px) {
    &:after {
      width: 43%;
    }
    &:before {
      width: 43%;
    }
  }

  @media screen and (max-width: 650px) {
    &:after {
      width: 40%;
    }
    &:before {
      width: 40%;
    }
  }

  @media screen and (max-width: 450px) {
    &:after {
      width: 36%;
    }
    &:before {
      width: 36%;
    }
  }
`;

const Line = styled.hr`
  background-color: black;
  width: 100%;
`;

const StyledButton = styled(Link)`
  background: none;
  border: 3px solid #a6d0dd;
  border-radius: 5px;
  color: black;
  display: block;
  font-weight: 600;
  text-decoration: none !important;
  font-size: 1.4rem;
  margin: 2rem auto;
  padding: 0.5rem 2rem;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  &:before {
    background: #a6d0dd;
    content: "";
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
  }

  &:after {
    background: #a6d0dd;
    content: "";
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
  }

  &:hover {
    color: black;
    &:after {
      height: 100%;
      width: 135%;
    }
  }

  &:after {
    height: 100%;
    left: -35%;
    top: 0;
    transform: skew(50deg);
    transition-duration: 0.6s;
    transform-origin: top left;
    width: 0;
  }
`;

export const OffersModule: React.FC<OffersModuleProps> = ({ noButton }) => {
  return (
    <StyledContainer noBorder>
      <Title>Oferta</Title>
      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-xs-center justify-content-sm-between">
        <OfferElement
          link="/offers#czyszczenie-zewnetrza-jachtu"
          id="czyszczenie-zewnetrza-jachtu"
          icon={Icon1}
          content1="Czyszczenie jachtu"
          content2="z zewnątrz"
        />
        <OfferElement
          link="/offers#czyszczenie-wnetrza-jachtu"
          id="czyszczenie-wnetrza-jachtu"
          icon={Icon2}
          content1="Czyszczenie jachtu"
          content2="wewnątrz"
        />
        <OfferElement
          link="/offers#czyszczenie-i-olejowanie-teaku"
          id="czyszczenie-i-olejowanie-teaku"
          icon={Icon3}
          content1="Czyszczenie"
          content2="i olejowanie teaku"
        />
        <OfferElement
          link="/offers#polerowanie-oraz-woskowanie-laminatu"
          id="polerowanie-oraz-woskowanie-laminatu"
          icon={Icon4}
          content1="Polerowanie"
          content2="i woskowanie laminatu"
        />
        <OfferElement
          link="/offers#czyszczenie-oraz-impregnacja-powierzchni-skorzanych"
          id="czyszczenie-oraz-impregnacja-powierzchni-skorzanych"
          icon={Icon5}
          content1="Czyszczenie"
          content2="i impregnacja skóry"
        />
        <OfferElement
          link="/offers#odbijacze"
          id="odbijacze"
          icon={Icon6}
          content1="Czyszczenie"
          content2="odbijaczy"
        />
        <OfferElement
          link="/offers#czyszczenie-i-konserwacja-powierzchni-stalowych"
          id="czyszczenie-i-konserwacja-powierzchni-stalowych"
          icon={Icon7}
          content1="Czyszczenie"
          content2="i konserwacja stali"
        />
        <OfferElement
          link="/offers#czyszczenie-tapicerek-i-dywanow"
          id="czyszczenie-tapicerek-i-dywanow"
          icon={Icon8}
          content1="Czyszczenie tapicerek"
          content2="i dywanów"
        />
        <OfferElement
          link="/offers#szycie-elementow-materialowych"
          id="szycie-elementow-materialowych"
          icon={Icon9}
          content1="Szycie elementów"
          content2=" materiałowych"
        />
        <OfferElement
          link="/offers#przygotowanie-jachtu-na-zime"
          id="przygotowanie-jachtu-na-zime"
          icon={Icon10}
          content1="Przygotowanie jachtu "
          content2="do sezonu"
        />
        <OfferElement
          link="/offers#przygotowanie-jachtu-do-sezonu"
          id="przygotowanie-jachtu-do-sezonu"
          icon={Icon11}
          content1="Przygotowanie jachtu"
          content2="na zimę"
        />
        <OfferElement
          link="/offers#projekty-spersonalizowanych-grafik-dla-calej-zalogi"
          id="projekty-spersonalizowanych-grafik-dla-calej-zalogi"
          icon={Icon12}
          content1="Projekt grafik"
          content2="na zamówienie"
        />
        <Line />
        {!noButton && (
          <StyledButton className="customButton" to="/offers">
            Zobacz pełną ofertę<span></span>
          </StyledButton>
        )}
      </div>
    </StyledContainer>
  );
};
