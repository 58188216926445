import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface NavItemProps {
  title: string;
  link: string;
  icon: string;
}

const Title = styled.span`
  text-transform: capitalize;
  text-align: center;
  color: white;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none !important;
  transition: all 0.2s ease;
  margin-left: 0.5rem;

  &:hover {
    opacity: 0.5;
  }
`;

const Icon = styled.img`
  height: 15px;
  width: 15px;
  object-fit: contain;
  margin-right: 0.25rem;
`;

export const NavItem: React.FC<NavItemProps> = ({ title, link, icon }) => {
  return (
    <StyledLink to={link}>
      <Icon alt={title} src={icon} />
      <Title>{title}</Title>
    </StyledLink>
  );
};
