import React, { useEffect, useMemo, useRef, useState } from "react";
import { Slider } from "../Components/Slider/Slider";

import Slider1Big from "../static/images/1 (3000).jpg";
import Slider1Mid from "../static/images/1 (2000).jpg";
import Slider1Small from "../static/images/1 (1000).jpg";

import Slider2Big from "../static/images/2 (3000).jpg";
import Slider2Mid from "../static/images/2 (2000).jpg";
import Slider2Small from "../static/images/2 (1000).jpg";

import Slider3Big from "../static/images/3 (3000).jpg";
import Slider3Mid from "../static/images/3 (2000).jpg";
import Slider3Small from "../static/images/3 (1000).jpg";

import SliderMobile from "../static/images/SliderMobile.jpg";

import WaveImage from "../static/images/icons/fala główna strona.png";
import styled from "styled-components";

import { OffersModule } from "../Components/OffersModule/OffersModule";

interface HomePageProps {
  loaded: boolean;
  loadedProps: boolean;
}

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    height: 100px;
  }
`;

export const HomePage: React.FC<HomePageProps> = () => {
  /* Detect width change */
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 300);
  }, []);

  useEffect(() => {
    if (loaded) {
      if (containerRef.current) {
        containerRef.current.classList.remove("preload-fix");
      }
    } else {
      if (containerRef.current) {
        containerRef.current.classList.add("preload-fix");
      }
    }
  }, [loaded]);

  const arrSlider = useMemo(
    () =>
      width < 900
        ? [SliderMobile]
        : width < 1001
        ? [Slider1Small, Slider2Small, Slider3Small]
        : width < 2000
        ? [Slider1Mid, Slider2Mid, Slider3Mid]
        : [Slider1Big, Slider2Big, Slider3Big],
    [width]
  );
  return (
    <>
      {loaded ? (
        <>
          <div ref={containerRef} className="preload-fix animated">
            <Slider
              delay={5000}
              controlsOnHover={true}
              showControls
              images={arrSlider}
            />
          </div>

          <Image src={WaveImage} alt="wave" />
          <OffersModule />
        </>
      ) : (
        <div className="preload-fix"></div>
      )}
    </>
  );
};
