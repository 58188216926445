import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface OfferElementProps {
  icon: string;
  content1: string;
  content2?: string;
  link: string;
  id?: string;
}

const StyledIcon = styled.img`
  width: 50px;
  object-fit: contain;
`;

const Inner = styled(Link)`
  transition: all 0.2s ease;
  width: 16%;
  border-radius: 10px;
  padding: 0.5rem;
  border: 1px solid transparent;
  text-align: center;
  color: black !important;
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.5rem;

  button {
    text-transform: capitalize;
    background-color: transparent !important;
    color: black !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #f1f1f1;
    border: 1px solid #b1b1b1;
  }
  @media screen and (max-width: 1280px) {
    width: 20%;
  }

  @media screen and (max-width: 900px) {
    width: 45%;
  }

  @media screen and (max-width: 400px) {
    width: 95%;
    margin-left: 0;
  }
`;

const Paragraph = styled.p`
  margin: 0;
  line-height: 1;
`;

export const OfferElement: React.FC<OfferElementProps> = ({
  icon,
  content1,
  content2,
  link,
  id,
}) => {
  function scrollToForm() {
    if (id) {
      document.querySelector(`#${id}`)?.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <Inner onClick={scrollToForm} to={link}>
      <StyledIcon alt={content1} src={icon} />
      <div className="d-flex flex-column align-items-center">
        <Paragraph>{content1}</Paragraph>
        <Paragraph>{content2}</Paragraph>
      </div>
    </Inner>
  );
};
