import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import LogoLoading from "../../static/images/icons/logo black.png";

interface FullPageLoadingProps {
  show: boolean;
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  top: 0;
  background-color: white;
  position: fixed;
  text-align: center;
`;
const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const FullPageLoading: React.FC<FullPageLoadingProps> = ({ show }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!show) {
      if (containerRef.current) {
        containerRef.current.classList.add("animateOut");
      }
    }
  }, [show]);
  return (
    <Container ref={containerRef}>
      <Image src={LogoLoading} alt="logo" />
    </Container>
  );
};
