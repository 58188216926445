import { useEffect, useLayoutEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router";
import styled from "styled-components";
import { Footer } from "./Components/Footer/Footer";
import { Navbar } from "./Components/Navbar/Navbar";
import { AboutPage } from "./Pages/AboutPage";
import { ContactPage } from "./Pages/ContactPage";
import { HomePage } from "./Pages/HomePage";
import { NotFoundPage } from "./Pages/NotFoundPage";
import { OffersPage } from "./Pages/OffersPage";

// import Contact from "./static/images/ContactBG.png";
import { FullPageLoading } from "./Components/FullPageLoading/FullPageLoading";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;
function App() {
  const [loaded, setLoaded] = useState(false);

  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location.hash, location.pathname]);
  useEffect(() => {
    window.addEventListener("load", () => {
      setTimeout(() => {
        setLoaded(true);
      }, 1000);
    });
  }, []);

  return (
    <Main>
      <>
        {/* {loaded && <Navbar />} */}
        {/* <img
          src={Slider1}
          style={{ position: "absolute", width: "0" }}
          alt=""
        />
        <img
          src={Slider2}
          style={{ position: "absolute", width: "0" }}
          alt=""
        />
        <img
          src={Slider3}
          style={{ position: "absolute", width: "0" }}
          alt=""
        />
        <img
          src={Contact}
          style={{ position: "absolute", width: "0" }}
          alt=""
        /> */}
        <Navbar />
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/offers" component={OffersPage} />
          <Route path="" component={NotFoundPage} />
        </Switch>
        <Footer />
      </>
      <FullPageLoading show={!loaded} />
    </Main>
  );
}

export default App;
